import { Link } from "react-router-dom";
import { useEffect } from "react";
import Transition from "../../common-comp/Transition";
import Form from "../../common-comp/form";
import { navigation } from "../../utils/navigation";
import { useDelayAndGo } from "../../hooks/useDelayAndGo";

export default function Branchetti() {
  const { delayAndGo } = useDelayAndGo();
  const lenis = window.lenis;

  useEffect(() => {
    lenis.scrollTo("top");
    TransitionIn();
  });

  window.lenis.scrollTo((0, 0), { immediate: true });

  function TransitionIn() {
    var elem = document.getElementById("transIn");
    elem.classList.add("animateTransition-in");
  }

  return (
    <>
      <Transition />
      <div className="works-desc-wp mt-70" id="top">
        <div className="rel">
          <p className="work-p">Monica Branchetti</p>
          <p className="work-subtitle mt-20">
            UX Design / Web Design / Graphic Design
          </p>
          <p className="work-subtitle mt-70">2024</p>
          {/*<div className="work-cta-container mobile-hidden">
            <a className="button-outline button--atlas-outline" id="footer-cta" href="https://monicabranchetti.com/" target="BLANK">
              <span>Visit Website</span>
              <div className="marqueebutton-outline" aria-hidden="true">
                <div className="marquee__inner-outline">
                  <span>Visit Website</span>
                  <span>Visit Website</span>
                  <span>Visit Website</span>
                  <span>Visit Website</span>
                  <span>Visit Website</span>

                  <span>Visit Website</span>
                  <span>Visit Website</span>
                  <span>Visit Website</span>
                  <span>Visit Website</span>
                  <span>Visit Website</span>
                </div>
              </div>
            </a>
          </div>*/}
        </div>
        <div>
          <p className="work-p">
            Monica embodies the true meaning of an Artist. A real painter,
            interior designer, and decorator, she communicates through her art
            with elegance and professionalism. Her website needed to represent
            her works of art and herself with a minimalist layout style,
            allowing her works, colors, and subjects to take center stage. The
            essential design ensures that the focus remains on the artworks,
            while intuitive navigation and a clear structure reflect her refined
            artistic sensibility.
          </p>
        </div>
        <div className="rel desk-hidden">
          <a
            className="button-outline button--atlas-outline"
            id="footer-cta"
            href="https://monicabranchetti.com/"
            target="BLANK"
          >
            <span>Visit Website</span>
            <div className="marqueebutton-outline" aria-hidden="true">
              <div className="marquee__inner-outline">
                <span>Visit Website</span>
                <span>Visit Website</span>
                <span>Visit Website</span>
                <span>Visit Website</span>
                <span>Visit Website</span>

                <span>Visit Website</span>
                <span>Visit Website</span>
                <span>Visit Website</span>
                <span>Visit Website</span>
                <span>Visit Website</span>
              </div>
            </div>
          </a>
        </div>
      </div>
      <div className="works-grid mobile-hidden" id="branchetti">
        <div className="row">
          <img src="/img/branchetti/firstop.png" alt="prova" />
        </div>
        <div className="row">
          <img src="/img/branchetti/sectop.png" alt="prova" />
        </div>
        <div className="row">
          <div className="inner-row">
            <div>
              <img src="/img/branchetti/secondf.png" alt="prova" />
            </div>
            <div>
              <img src="/img/branchetti/seconds.png" alt="prova" />
            </div>
          </div>
        </div>
        <div className="row"></div>
        <div className="row"></div>
        <div className="row">
          <div className="inner-row">
            <div>
              <img src="/img/branchetti/fourthf.png" alt="prova" />
            </div>
            <div>
              <img src="/img/branchetti/fourths.png" alt="prova" />
            </div>
          </div>
        </div>
        <div className="row-sm"></div>
        <div className="row">
          <div className="inner-row">
            <div>
              <img src="/img/branchetti/innerf.png" alt="prova" />
            </div>
            <div>
              <img src="/img/branchetti/inners.png" alt="prova" />
            </div>
            <div>
              <img src="/img/branchetti/innert.png" alt="prova" />
            </div>
          </div>
        </div>
      </div>
      <div className="works-mob desk-hidden" id="branchetti-mob">
        <div>
          <img src="/img/branchetti/1-1.png" alt="img" />
        </div>
        <div>
          <img src="/img/branchetti/1-2.png" alt="img" />
        </div>
        <div>
          <img src="/img/branchetti/2.png" alt="img" />
        </div>
        <div>
          <img src="/img/branchetti/3.png" alt="img" />
        </div>
        <div>
          <img src="/img/branchetti/4-1.png" alt="img" />
        </div>
        <div>
          <img src="/img/branchetti/4-2.png" alt="img" />
        </div>
        <div>
          <img src="/img/branchetti/5.png" alt="img" />
        </div>
      </div>
      <div className="works-desc-wp next-wp">
        <div className="prev-next-style">
          <Link
            to={navigation.alexandra}
            onClick={(e) => delayAndGo(e, navigation.alexandra)}
          >
            <p className="cta-page-switch">
              <button>Prev</button>
            </p>
          </Link>
          <img src="/img/previous.svg" alt="previous" />
        </div>
        <div className="prev-next-style">
          <Link
            to={navigation.easea}
            onClick={(e) => delayAndGo(e, navigation.easea)}
          >
            <p className="cta-page-switch">
              <button>Next</button>
            </p>
          </Link>
          <img src="/img/next.svg" alt="next" />
        </div>
      </div>
      <div className="r-5 mt-120">
        <div className="text-wrapper">
          <h1>
            <span className="circle"></span>Graphic Design
          </h1>
          <h1>
            <span className="circle"></span>Logo Design
          </h1>
          <h1>
            <span className="circle"></span>Web Design
          </h1>
          <h1>
            <span className="circle"></span>Graphic Design
          </h1>
          <h1>
            <span className="circle"></span>Logo Design
          </h1>

          <h1>
            <span className="circle"></span>Web Design
          </h1>
          <h1>
            <span className="circle"></span>Graphic Design
          </h1>
          <h1>
            <span className="circle"></span>Logo Design
          </h1>
          <h1>
            <span className="circle"></span>Web Design
          </h1>
          <h1>
            <span className="circle"></span>Graphic Design
          </h1>
        </div>
      </div>
      <Form />
    </>
  );
}
