export const navigation = {
  home: "/",
  about: "/about",
  works: "/works",
  vanni: "/works/vanni",
  orchestra: "/works/Orchestra",
  bisteccamore: "/works/Bisteccamore",
  branchetti: "/works/Branchetti",
  studioBigne: "/works/StudioBigne",
  cesareLampronti: "/works/CesareLampronti",
  pegaso: "/works/Pegaso",
  percorsiSomatici: "/works/PercorsiSomatici",
  guiaNerli: "/works/GuiaNerli",
  agricola: "/works/agricola",
  viaggi: "/works/viaggi",
  alexandra: "/works/alexandra",
  chimera: "/works/chimera",
  easea: "/works/easea",
  mancinelli: "/works/mancinelli",
  gastone: "/works/gastone",
};
