import ReactDOM from "react-dom/client";
import "./styles/index.css";
import { RouterProvider } from "react-router-dom";

import { ParallaxProvider } from "react-scroll-parallax";
import { React } from "react";
import Lenis from "@studio-freight/lenis";

import { routes } from "./routes.js";
import { SnackbarProvider } from "notistack";

// import Distortion from './utils/Distortion.jsx';

const lenis = new Lenis({
  duration: 2,
  easing: (t) => Math.min(1, 1.001 - Math.pow(2, -10 * t)),
  direction: "vertical",
  gestureDirection: "vertical",
  smooth: true,
  smoothTouch: true,
  smoothWheel: true,
  touchMultiplier: 2,
});

window.lenis = lenis;

function raf(time) {
  lenis.raf(time);
  requestAnimationFrame(raf);
}

window.setTimeout(
  (document.onreadystatechange = function () {
    document.querySelectorAll('a[href^="#"].scrollto').forEach((anchor) => {
      anchor.addEventListener("click", function (e) {
        e.preventDefault();
        lenis.scrollTo(this.getAttribute("href"));
      });
    });
  }),
  200
);

window.onload = function () {
  window.localStorage.clear();
  let backToTop = document.getElementById("BTTop");
  let menu_option = "'menu'";
  let menu_other_option = "'x'";
  let menuToggle = document.querySelector(".menu-toggle");
  let menuToggle_after = window.getComputedStyle(menuToggle, "::before");
  menuToggle.onclick = function () {
    if (menuToggle_after.content === '"menu"') {
      backToTop.classList.add("disappearBTTop");
    } else {
      backToTop.classList.remove("disappearBTTop");
    }
    setTimeout(() => {
      menuToggle.classList.toggle("active");
    }, 850);
    setTimeout(function () {
      if (menuToggle_after.content === '"menu"') {
        menuToggle.style.setProperty("--menu-content", menu_other_option);
      } else {
        menuToggle.style.setProperty("--menu-content", menu_option);
      }
    }, 1350);
  };
};

function App() {
  var navigation;
  var backToTop;
  var menuToggle;

  document.onreadystatechange = function () {
    if (document.readyState === "complete") {
      navigation = document.getElementById("btNav");
      backToTop = document.getElementById("BTTop");
      menuToggle = document.querySelector(".menu-toggle");
      window.addEventListener("scroll", function (e) {
        navigation.classList.add("navigation-anim");
        backToTop.classList.add("appearBTTop");
      });
      menuToggle.addEventListener("click", function (e) {
        console.log("cliccato");
      });
    }
  };

  raf();
  const root = ReactDOM.createRoot(document.getElementById("root"));
  root.render(
    <>
      <ParallaxProvider>
        <RouterProvider router={routes} />
      </ParallaxProvider>
      <SnackbarProvider
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      />
    </>
  );
}

App();
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(console.log);
