import { enqueueSnackbar } from "notistack";
import { StrictMode, useState } from "react";
import { useForm } from "react-hook-form";

export default function Form() {
  const [loading, setLoading] = useState(false);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: "",
      email: "",
      subject: "",
    },
  });

  const onSubmit = async (values) => {
    setLoading(true);
    try {
      const res = await fetch("https://lauralavorini-be.vercel.app/contact", {
        method: "POST",
        body: JSON.stringify(values),
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
        },
      });
      await res.json();
      setLoading(false);
      reset();
      enqueueSnackbar("Message send successfully", { variant: "success" });
    } catch (e) {
      enqueueSnackbar("Something wrong..", { variant: "error" });
      setLoading(false);
      console.log(e);
    }
  };
  return (
    <StrictMode>
      <section className="form-wrapper" id="form">
        <h2 className="form-title">Drop me a line</h2>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="form-container">
            <div className="form-column">
              <p className="title-form ">Name</p>
              <input
                type="text"
                className="form-item"
                placeholder="Name"
                id="name"
                {...register("name", { required: "Required" })}
              />
              {errors?.name?.message ? (
                <p className="form-error-msg">{errors.name.message}</p>
              ) : null}
            </div>
            <div className="form-column">
              <p className="title-form">Email</p>
              <input
                type="email"
                className="form-item"
                placeholder="Email"
                {...register("email", { required: "Required" })}
              />
              {errors?.email?.message ? (
                <p className="form-error-msg">{errors.email.message}</p>
              ) : null}
            </div>

            <div className="form-column">
              <p className="title-form">Object</p>
              <input
                type="text"
                className="form-item"
                placeholder="Object"
                {...register("subject", { required: "Required" })}
              />
              {errors?.subject?.message ? (
                <p className="form-error-msg">{errors.subject.message}</p>
              ) : null}
            </div>

            <div className="ml-10 form-column cta">
              <p className="title-form">Send</p>
              <button
                className="form-button ml-10"
                type="submit"
                disabled={loading}
              >
                {loading ? (
                  <span className="loader-ll-w" />
                ) : (
                  <span className="material-symbols-outlined">
                    arrow_forward
                  </span>
                )}
              </button>
            </div>
          </div>
        </form>
      </section>
    </StrictMode>
  );
}
