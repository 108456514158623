import { Link } from "react-router-dom";
import { StrictMode } from "react";
import { HashLink } from "react-router-hash-link";
import { useDelayAndGo } from "../hooks/useDelayAndGo";
import { navigation } from "../utils/navigation";

export default function Navigation() {
  const { delayAndGo } = useDelayAndGo();

  return (
    <>
      <StrictMode>
        <span id="BTTop">
          <a href="#top" className="scrollto">
            <img src="/img/back.png" alt="back" />
          </a>
        </span>
        <section className="navigation" id="btNav">
          <div className="menu-toggle">
            <ul>
              <li>
                <Link
                  to={navigation.home}
                  onClick={(e) => delayAndGo(e, navigation.home)}
                  className="nav-item d-500"
                >
                  Home
                </Link>
              </li>
              <li>
                <Link
                  to={navigation.about}
                  onClick={(e) => delayAndGo(e, navigation.about)}
                  className="nav-item d-600"
                >
                  about
                </Link>
              </li>
              <li>
                <HashLink
                  smooth
                  to={navigation.works}
                  onClick={(e) => delayAndGo(e, navigation.works)}
                  className="nav-item d-700"
                >
                  works
                </HashLink>
              </li>
              <li>
                <a className="nav-item d-800 scrollto" href="#form">
                  contact
                </a>
              </li>
            </ul>
          </div>
        </section>
      </StrictMode>
    </>
  );
}
