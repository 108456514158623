import { Link } from "react-router-dom";
import { useEffect } from "react";
import Transition from "../../common-comp/Transition";
import Form from "../../common-comp/form";
import threeOne from "../../img/viaggi/1-1.png";
import threeTwo from "../../img/viaggi/1-2.jpg";
import fourOne from "../../img/viaggi/4-1.png";
import fourTwo from "../../img/viaggi/4-2.png";
import { navigation } from "../../utils/navigation";
import { useDelayAndGo } from "../../hooks/useDelayAndGo";

export default function Viaggi() {
  const lenis = window.lenis;
  const { delayAndGo } = useDelayAndGo();

  useEffect(() => {
    lenis.scrollTo("top");
    TransitionIn();
  });

  window.lenis.scrollTo((0, 0), { immediate: true });

  function TransitionIn() {
    var elem = document.getElementById("transIn");
    elem.classList.add("animateTransition-in");
  }

  return (
    <>
      <Transition />
      <div className="works-desc-wp mt-70" id="top">
        <div className="rel">
          <p className="work-p">Nuove Idee Viaggi by Elisa</p>
          <p className="work-subtitle mt-20">Web Design</p>
          <p className="work-subtitle mt-70">2024</p>
          <div className="work-cta-container mobile-hidden">
            <a
              className="button-outline button--atlas-outline"
              id="footer-cta"
              href="https://nuoveideeviaggibyelisa.it/"
              target="BLANK"
            >
              <span>Visit Website</span>
              <div className="marqueebutton-outline" aria-hidden="true">
                <div className="marquee__inner-outline">
                  <span>Visit Website</span>
                  <span>Visit Website</span>
                  <span>Visit Website</span>
                  <span>Visit Website</span>
                  <span>Visit Website</span>

                  <span>Visit Website</span>
                  <span>Visit Website</span>
                  <span>Visit Website</span>
                  <span>Visit Website</span>
                  <span>Visit Website</span>
                </div>
              </div>
            </a>
          </div>
        </div>
        <div>
          <p className="work-p">
            Nuove Idee Viaggi by Elisa, an online travel agency, needed a
            website that would captivate and inspire potential travelers. I
            focused on creating an engaging and user-friendly interface that
            reflects the excitement and adventure of travel, making it easy for
            users to plan their next journey.
          </p>
        </div>
        <div className="rel desk-hidden">
          <a
            className="button-outline button--atlas-outline"
            id="footer-cta"
            href="https://nuoveideeviaggibyelisa.it/"
            target="BLANK"
          >
            <span>Visit Website</span>
            <div className="marqueebutton-outline" aria-hidden="true">
              <div className="marquee__inner-outline">
                <span>Visit Website</span>
                <span>Visit Website</span>
                <span>Visit Website</span>
                <span>Visit Website</span>
                <span>Visit Website</span>

                <span>Visit Website</span>
                <span>Visit Website</span>
                <span>Visit Website</span>
                <span>Visit Website</span>
                <span>Visit Website</span>
              </div>
            </div>
          </a>
        </div>
      </div>
      <div className="works-grid mobile-hidden" id="viaggi">
        <div className="row" id="first">
          <div className="work-grid-2">
            <div></div>
            <div></div>
          </div>
        </div>
        <div className="row" id="second"></div>
        <div className="row" id="third"></div>
        <div className="row" id="fourth">
          <div className="work-grid-2">
            <div></div>
            <div></div>
          </div>
        </div>
        <div className="row" id="fifth"></div>
      </div>
      <div className="works-mob desk-hidden" id="viaggi-mob">
        <div>
          <img src={threeOne} alt="img" />
        </div>
        <div>
          <img src={threeTwo} alt="img" />
        </div>
        <div>
          <img src="/img/viaggi/2-mob.png" alt="img" />
        </div>
        <div>
          <img src="/img/viaggi/3-mob.png" alt="img" />
        </div>
        <div>
          <img src={fourOne} alt="img" />
        </div>
        <div>
          <img src={fourTwo} alt="img" />
        </div>
        <div>
          <img src="/img/viaggi/5-mob.png" alt="img" />
        </div>
      </div>
      <div className="works-desc-wp next-wp">
        <div className="prev-next-style">
          <Link onClick={(e) => delayAndGo(e, navigation.orchestra)}>
            <p className="cta-page-switch">
              <button>Prev</button>
            </p>
          </Link>
          <img src="/img/previous.svg" alt="previous" />
        </div>
        <div className="prev-next-style">
          <Link onClick={(e) => delayAndGo(e, navigation.pegaso)}>
            <p className="cta-page-switch">
              <button>Next</button>
            </p>
          </Link>
          <img src="/img/next.svg" alt="next" />
        </div>
      </div>
      <div className="r-5 mt-120">
        <div className="text-wrapper">
          <h1>
            <span className="circle"></span>Graphic Design
          </h1>
          <h1>
            <span className="circle"></span>Logo Design
          </h1>
          <h1>
            <span className="circle"></span>Web Design
          </h1>
          <h1>
            <span className="circle"></span>Graphic Design
          </h1>
          <h1>
            <span className="circle"></span>Logo Design
          </h1>

          <h1>
            <span className="circle"></span>Web Design
          </h1>
          <h1>
            <span className="circle"></span>Graphic Design
          </h1>
          <h1>
            <span className="circle"></span>Logo Design
          </h1>
          <h1>
            <span className="circle"></span>Web Design
          </h1>
          <h1>
            <span className="circle"></span>Graphic Design
          </h1>
        </div>
      </div>
      <Form />
    </>
  );
}
