import { useNavigate } from "react-router-dom";

export const useDelayAndGo = () => {
  const navigate = useNavigate();

  function delayAndGo(e, path) {
    e.preventDefault();
    var elem = document.getElementById("transOut");
    elem.classList.add("animateTransition");
    setTimeout(() => {
      navigate(path);
      elem.classList.remove("animateTransition");
    }, 1500);
  }
  return {
    delayAndGo,
  };
};
