import { createHashRouter } from "react-router-dom";
import AppLayout from "./common-comp/AppLayout";
import PageNotFound from "./pages/404";
import Home from "./pages/Home";
import About from "./pages/About";
import Works from "./common-comp/Works";
import Vanni from "./pages/work-pages/Vanni";
import Orchestra from "./pages/work-pages/Orchestra";
import { navigation } from "./utils/navigation";
import Bisteccamore from "./pages/work-pages/Bisteccamore";
import Branchetti from "./pages/work-pages/Branchetti";
import StudioBigne from "./pages/work-pages/StudioBigne";
import CesareLampronti from "./pages/work-pages/CesareLampronti";
import Pegaso from "./pages/work-pages/Pegaso";
import PercorsiSomatici from "./pages/work-pages/PercorsiSomatici";
import GuiaNerli from "./pages/work-pages/GuiaNerli";
import Agricola from "./pages/work-pages/Agricola";
import Viaggi from "./pages/work-pages/Viaggi";
import Easea from "./pages/work-pages/Easea";
import Mancinelli from "./pages/work-pages/Mancinelli";
import Gastone from "./pages/work-pages/AlexandraMichelozzi";
import Chimera from "./pages/work-pages/Chimera";
import Alexandra from "./pages/work-pages/AlexandraMichelozzi";

export const routes = createHashRouter([
  {
    path: "/",
    element: <AppLayout />,
    children: [
      {
        path: navigation.home,
        element: <Home />,
      },
      {
        path: navigation.about,
        element: <About />,
      },
      {
        path: navigation.works,
        element: <Works />,
      },
      {
        path: navigation.vanni,
        element: <Vanni />,
      },
      {
        path: navigation.orchestra,
        element: <Orchestra />,
      },
      {
        path: navigation.bisteccamore,
        element: <Bisteccamore />,
      },
      {
        path: navigation.branchetti,
        element: <Branchetti />,
      },
      {
        path: navigation.studioBigne,
        element: <StudioBigne />,
      },
      {
        path: navigation.cesareLampronti,
        element: <CesareLampronti />,
      },
      {
        path: navigation.pegaso,
        element: <Pegaso />,
      },
      {
        path: navigation.percorsiSomatici,
        element: <PercorsiSomatici />,
      },
      {
        path: navigation.guiaNerli,
        element: <GuiaNerli />,
      },
      {
        path: navigation.agricola,
        element: <Agricola />,
      },
      {
        path: navigation.viaggi,
        element: <Viaggi />,
      },
      {
        path: navigation.alexandra,
        element: <Alexandra />,
      },
      {
        path: navigation.chimera,
        element: <Chimera />,
      },
      {
        path: navigation.easea,
        element: <Easea />,
      },
      {
        path: navigation.mancinelli,
        element: <Mancinelli />,
      },
      {
        path: navigation.gastone,
        element: <Gastone />,
      },
      {
        path: "*",
        element: <PageNotFound />,
      },
    ],
  },
]);
